<template>
	<div>
		<BRow>
			<BCol class="subtitle mt-n3 mb-2">
				<h2>{{ $t('subtitle') }}</h2>
			</BCol>
		</BRow>
		<BRow class="mt-1">
			<BCol>
				<BaseTabs class="tabs" :tabs="tabs" :automation-id="getAutomationId('premium-payments')">
					<!--**************** CREDIT CARD TAB **************-->
					<template #0>
						<PremiumDetailsCreditCard
							:credit-card="creditCardNumber"
							:expiry-date="expiryDate"
							:has-credit="hasCreditInfo"
							:automation-id="getAutomationId('premium-payments')"
						/>
					</template>
					<!--**************** DEBIT TAB **************-->
					<template #1>
						<PremiumDetailsDebit
							:has-debit="!hasCreditInfo"
							:automation-id="getAutomationId('premium-payments')"
						/>
					</template>
				</BaseTabs>
			</BCol>
		</BRow>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import isMobile from '@/mixins/mobile';
import { BRow, BCol } from 'bootstrap-vue';
import PremiumDetailsCreditCard from '@/components/payment/PremiumDetailsCreditCard';
import PremiumDetailsDebit from '@/components/payment/PremiumDetailsDebit';
import BaseTabs from '@/components/common/base/BaseTabs';

// @vue/component
@Component({
	name: 'PremiumPaymentDetails',
	components: {
		BRow,
		BCol,
		BaseTabs,
		PremiumDetailsCreditCard,
		PremiumDetailsDebit
	},
	mixins: [IdMixin, isMobile],
	props: {
		creditCardNumber: {
			type: String,
			default: null
		},
		expiryDate: {
			type: String,
			default: null
		},
		hasCreditInfo: {
			type: Boolean,
			default: null
		},
		automationId: {
			type: String,
			default: null
		}
	},
	watch: {
		locale() {
			this.updateTabs();
		}
	}
})
export default class PremiumPaymentDetails extends Vue {
	tabs = [];

	/**
	 * computed property to get the local to determine what image to display.
	 */
	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	/**
	 * This computed property is used for the watch to know when to call
	 * the updateTabs method.
	 */
	get locale() {
		return this.$store.state.i18n.locale;
	}

	/*
	 * Will set the values in the tabs array.
	 */
	mounted() {
		this.tabs.push(this.$t('tab.creditCard'));
		this.tabs.push(this.$t('tab.preAuthDebit'));
	}
	/**
	 * This method is called when the local changes to update the tabs array with the correct locale.
	 */
	updateTabs() {
		this.tabs = [];
		this.tabs.push(this.$t('tab.creditCard'));
		this.tabs.push(this.$t('tab.preAuthDebit'));
	}
}
</script>

<style lang="scss" scoped>
.title {
	font-size: $header-main-font-size;
}
.subtitle h2 {
	font-size: 1.3125em;
	font-family: $body-small-font-family;
	font-weight: $body-small-font-weight;
}
.updateCreditBtn {
	min-width: 109px;
	padding: 0px 30px;
}
.cardNumberLabel {
	font-family: $josefin-font-family;
	font-weight: $font-regular;
	font-size: 1.1875em;
	margin-bottom: 0;
}
.lightLabel {
	font-family: $lato-font-family;
	font-weight: $font-light;
	font-size: $header-sub-font-size;
}
.logo {
	height: 3em;
}
.imageMarginLeft {
	margin-left: 10px;
}
</style>

<i18n>
{
  "en": {
    "subtitle": "How would you like to pay for your premiums?",
    "url": {},
    "tab": {
      "creditCard": "Credit Card",
      "preAuthDebit": "Pre-Authorized Debit"
    }
  },
  "fr": {
    "subtitle": "Comment souhaitez-vous payer vos primes ?",
    "url": {},
    "tab": {
      "creditCard": "Carte de crédit",
      "preAuthDebit": "Prélèvement automatique"
    }
  }
}
</i18n>
