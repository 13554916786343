import { Model } from '@vuex-orm/core';
import axios from 'axios';

/**
 * This class represent a Member
 */
export default class CreditCard extends Model {
	/**
	 * This method will call the api to return all the users credit card info..
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 * @returns
	 */
	static async getMaskedCreditCard(username, token, locale) {
		try {
			var url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/credit-card`;
			let response = await axios
				.get(url, {
					headers: {
						'content-type': 'application/json',
						Authorization: token,
						'Accept-Language': locale
					},
					data: {}
				})
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}
	/**
	 * This method will call the api to UPDATE the users credit card info..
	 * @param {String} username
	 * @param {String} issuerId
	 * @param {String} ccToken
	 * @param {String} token
	 * @param {String} locale
	 * @returns
	 */
	static async updateCreditCard(username, issuerId, ccToken, creditCardId, token, locale) {
		try {
			var url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/credit-card/${creditCardId}`;
			let response = await axios
				.put(
					url,
					{
						issuerId: issuerId,
						token: ccToken
					},
					{
						headers: {
							'content-type': 'application/json',
							Authorization: token,
							'Accept-Language': locale
						},
						data: {}
					}
				)
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}
	/**
	 * This method will call the api to ADD the users credit card info..
	 * @param {String} username
	 * @param {String} issuerId
	 * @param {String} ccToken
	 * @param {String} token
	 * @param {String} locale
	 * @returns
	 */
	static async addCreditCard(username, issuerId, ccToken, token, locale) {
		try {
			var url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/credit-card`;
			let response = await axios
				.post(
					url,
					{
						issuerId: issuerId,
						token: ccToken
					},
					{
						headers: {
							'content-type': 'application/json',
							Authorization: token,
							'Accept-Language': locale
						},
						data: {}
					}
				)
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * This method will call the api and get the ticket from moneris to display the correct moneris checkout.
	 * @param {String} username
	 * @param {Boolean} isMedavieMoneris
	 * @param {String} token
	 * @param {String} locale
	 * @returns
	 */
	static async getMonerisTicket(username, isMedavieMoneris, token, locale) {
		try {
			var url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/moneris-checkout-pre-load`;
			let response = await axios
				.post(
					url,
					{
						medavieMoneris: isMedavieMoneris
					},
					{
						headers: {
							'content-type': 'application/json',
							Authorization: token,
							'Accept-Language': locale
						},
						data: {}
					}
				)
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * This method will call the api to get the correct information from the moneris reciept to update/add the members credit card info
	 * @param {String} username
	 * @param {String} ticket
	 * @param {Boolean} isMedavieMoneris
	 * @param {String} token
	 * @param {String} locale
	 * @returns
	 */
	static async getMonerisReciept(username, ticket, isMedavieMoneris, token, locale) {
		try {
			var url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/moneris-checkout-receipt`;
			let response = await axios
				.post(
					url,
					{
						ticket: ticket,
						medavieMoneris: isMedavieMoneris
					},
					{
						headers: {
							'content-type': 'application/json',
							Authorization: token,
							'Accept-Language': locale
						},
						data: {}
					}
				)
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}
}
