import { render, staticRenderFns } from "./PremiumPaymentDetails.vue?vue&type=template&id=b1e77cde&scoped=true&"
import script from "./PremiumPaymentDetails.vue?vue&type=script&lang=js&"
export * from "./PremiumPaymentDetails.vue?vue&type=script&lang=js&"
import style0 from "./PremiumPaymentDetails.vue?vue&type=style&index=0&id=b1e77cde&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1e77cde",
  null
  
)

/* custom blocks */
import block0 from "./PremiumPaymentDetails.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports