<template>
	<ManageYourAccountTemplate :page-title="$t('title')">
		<PremiumPaymentDetails
			:credit-card-number="maskedCardNumber"
			:expiry-date="cardExpiryDate"
			:has-credit-info="hasCreditCard"
			automation-id="premiumPaymentsPage"
		/>
		<ErrorModal
			:error-title="$t('error.title')"
			class="error-modal"
			:has-close-button="false"
			:modal-show="showErrorModal"
			automation-id="premium-error-modal"
			@change="showErrorModal = false"
		>
			<p>
				{{ $t('error.description')
				}}<span><PhoneNumberDisplay :phone-number="padPhoneNumberInfo" /></span>.
			</p>
			<BaseButton
				class="mt-2"
				:label="$t('error.button')"
				:aria-label="$t('error.button')"
				variant="primary"
				automation-id="premium-error-modal-button"
				@click="showErrorModal = false"
			/>
		</ErrorModal>
	</ManageYourAccountTemplate>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import CreditCard from '@/models/CreditCard';
import { DASHBOARD, MANAGE_YOUR_ACCOUNT } from '@/constants/Routes.js';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import PremiumPaymentDetails from '@/components/payment/PremiumPaymentDetails';
import ManageYourAccountTemplate from '../ManageYourAccountTemplate.vue';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton';
import memberCardInfo from '@/models/MemberCardInfo';
import PhoneNumberDisplay from '@/components/common/PhoneNumberDisplay';

import { formatPhoneNumberWithParentheses } from '@/utils/commonUtils.js';
//utils
import axios from 'axios';

// @vue/component
@Component({
	name: 'PremiumPaymentMethodPage',
	components: {
		PremiumPaymentDetails,
		ManageYourAccountTemplate,
		ErrorModal,
		BaseButton,
		PhoneNumberDisplay
	},
	mixins: [IdMixin, BreadcrumbsManager]
})
export default class PremiumPaymentMethodPage extends Vue {
	maskedCardNumber = null;
	cardExpiryDate = null;
	hasCreditCard = false;
	maskedCardNumberQuery = this.$route?.params?.maskedPan;
	cardExpiryDateQuery = this.$route?.params?.expiryDate;
	showSuccessBanner = this.$route?.params?.success;
	showErrorModal = this.$route?.params?.error ? this.$route?.params?.error : false;
	padPhoneNumber = null;

	get padPhoneNumberInfo() {
		return formatPhoneNumberWithParentheses(this.padPhoneNumber);
	}

	get errorModalDescription() {
		return this.$t('error.description', { 0: this.padPhoneNumberInfo });
	}

	// Set the path for the BreadCrumbs Manager.
	created() {
		this.getResourceUrls();
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'en'), to: { name: MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.paymentMethod', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'fr'), to: { name: MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.paymentMethod', 'fr') }
			]
		);

		// Check if there's query params with the values (Query Params created by MonerisBlankIframe.vue redirect)
		if (this.maskedCardNumberQuery && this.cardExpiryDateQuery) {
			this.maskedCardNumber = this.maskedCardNumberQuery;
			this.cardExpiryDate =
				this.cardExpiryDateQuery.substring(0, 2) + '/' + this.cardExpiryDateQuery.substring(2, 4);
			this.hasCreditCard = true;
		} else {
			//If none then we go see if exists
			this.getMaskedCreditCardInfo();
		}

		//Check to see if we need to show success banner
		if (this.showSuccessBanner) {
			this.$store.dispatch('successBanner', {
				show: true,
				headerText: this.$t('successTitle'),
				text: this.$t('success')
			});
		} else {
			this.$store.dispatch('successBanner', { show: false });
		}
	}

	/**
	 * Call the API to get the members credit card info if they have some to know what screens to display.
	 */
	getMaskedCreditCardInfo() {
		this.$store.dispatch('updateLoading', true);
		CreditCard.getMaskedCreditCard(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)
			.then((response) => {
				if (response && (response.status === 201 || response.status === 200)) {
					this.hasCreditCard = true;
					this.maskedCardNumber = response.data.maskedPan;
					this.cardExpiryDate =
						response.data.expiryDate.substring(0, 2) +
						'/' +
						response.data.expiryDate.substring(2, 4);
				} else if (response && response.status === 204) {
					this.hasCreditInfo = false;
				} else {
					this.$store.dispatch('error', {
						name: 'premium-payments-error',
						title: this.$t('error.wentWrongHeader'),
						err: this.$t('error.wentWrong')
					});
				}
			})
			.finally(() => {
				this.$store.dispatch('updateLoading', false);
				this.loading = false;
			});
		this.$store.dispatch('updateLoading', false);
	}

	/**
	 * Retrieve the resource urls from the API to use the urls to determine the last updated date for the privacy policy and the terms and conditions
	 */
	async getResourceUrls() {
		try {
			const resourceResponse = await memberCardInfo.getResourceUrlsByEmail(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$store.state.i18n.locale
			);
			this.resource = await axios.get(encodeURI(resourceResponse.data.json_padUrl));
			this.padPhoneNumber = this.resource?.data?.phoneNumber;
		} catch (e) {
			this.$store.dispatch('error', {
				name: 'premium-payments-error',
				title: this.$t('error.wentWrongHeader'),
				err: this.$t('error.wentWrong')
			});
		}
	}
}
</script>

<style lang="scss" scoped>
.premiumPaymentContainer {
	margin-left: 6em;
	@media (max-width: 768px) {
		margin-left: 3em;
	}
	@media (max-width: 500px) {
		margin-left: 0em;
	}
}
</style>

<i18n>
{
    "en": {
		"title": "Premium Payment Method",
        "breadcrumb": {
			"home": "Home",
			"memberInfo": "Manage your account",
			"paymentMethod": "Update payment method"
		},
		"successTitle": "Success!",
        "success": "Your payment method has been updated.",
		"error": {
			"title": "Please try again.",
			"description": "Please try again. If the issue persists please call us for assistance ",
			"button": "OK",
			"wentWrongHeader": "Something went Wrong",
			"wentWrong": "Oops, something went wrong. Please try again later."
		}

    },
    "fr": {
		"title": "Mode de paiement des primes",
        "breadcrumb": {
			"home": "Accueil",
			"memberInfo": "Gérer votre compte",
			"paymentMethod": "Modifier le mode de paiement"
		},
		"successTitle": "C’est fait!",
        "success": "Votre mode de paiement a été mis à jour.",
		"error": {
			"title": "Veuillez réessayer.",
			"description": "Veuillez réessayer. Si le problème persiste, téléphonez-nous au ",
			"button": "OK",
			"wentWrongHeader": "Une erreur s’est produite",
			"wentWrong": "Nous sommes désolés, une erreur s’est produite. Veuillez réessayer plus tard."
		}
    }
}
</i18n>
