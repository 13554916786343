<template>
	<div class="tabContent p-4">
		<!--********* HAS Debit information ************-->
		<template v-if="hasDebit">
			<BRow>
				<BCol>
					<h3>
						{{ $t('debit.info.title') }}
					</h3>
				</BCol>
			</BRow>
			<BRow>
				<BCol>
					<h4>
						{{ $t('debit.info.change') }}
					</h4>
				</BCol>
			</BRow>
			<BRow>
				<BCol>
					<p class="lightLabel">{{ padTextInfo }}</p>
				</BCol>
			</BRow>
		</template>
		<template v-else>
			<BRow>
				<BCol>
					<h3>
						{{ $t('debit.noInfo.title') }}
					</h3>
				</BCol>
			</BRow>
		</template>
		<BRow>
			<BCol>
				<p class="lightLabel">{{ $t('debit.info.reminder') }}</p>
			</BCol>
		</BRow>
		<BRow>
			<!-- Yes I did this if statement just to use a different css class -->
			<template v-if="isEnglish">
				<BCol>
					<BaseButton
						:label="$t('button.download')"
						class="mb-4"
						:automation-id="getAutomationId('premium-payments-debit')"
						:data-tracking-id="getAutomationId('premium-payments-debit')"
						@click="downloadForm()"
					/>
				</BCol>
			</template>
			<template v-else>
				<BCol>
					<BaseButton
						:label="$t('button.download')"
						class="mb-4"
						:automation-id="getAutomationId('premium-payments-debit')"
						:data-tracking-id="getAutomationId('premium-payments-debit')"
						@click="downloadForm()"
					/>
				</BCol>
			</template>
		</BRow>
		<BRow>
			<BCol>
				<h4>
					{{ padSubmitInfo }}
				</h4>
			</BCol>
		</BRow>
		<template v-if="brand === 'OBC' || brand === 'QBC'">
			<BRow>
				<BCol>
					<h5>
						{{ $t('debit.info.secureUpload') }}
					</h5>
				</BCol>
			</BRow>
			<BRow class="mb-4">
				<BCol>
					<a class="email-link" target="_blank" :href="padLink">{{ padLink }}</a>
				</BCol>
			</BRow>
		</template>
		<template v-else>
			<BRow>
				<BCol>
					<h5>
						{{ $t('debit.info.email') }}
					</h5>
				</BCol>
			</BRow>
			<BRow class="mb-4">
				<BCol>
					<a class="email-link" href="mailto:ics@medaviebc.ca">{{
						$t('debit.info.emailAddress')
					}}</a>
				</BCol>
			</BRow>
		</template>
		<BRow>
			<BCol>
				<h5>{{ padMailInfo }}</h5>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<p class="mb-0">
					{{ padTitleInfo }}<br />
					{{ padStreetInfo }}<br />
					<span v-if="padStreetInfo2">{{ padStreetInfo2 }}</span>
					<br v-if="padStreetInfo2" />
					{{ padCityInfo }}<br v-if="padCityInfo" />
				</p>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<PaymentMethodHelp :phone-number="padPhoneNumberInfo" require-dash :hours="padHoursInfo" />
			</BCol>
		</BRow>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
//Mixins
import IdMixin from '@/mixins/id';
import isMobile from '@/mixins/mobile';
//Components
import { BRow, BCol } from 'bootstrap-vue';
import PaymentMethodHelp from '@/components/common/PaymentMethodHelp';
import BaseButton from '@/components/common/base/BaseButton';
//Model
import Forms from '@/models/Forms';
import memberCardInfo from '@/models/MemberCardInfo';
// util
import axios from 'axios';

// @vue/component
@Component({
	name: 'PremiumDetailsDebit',
	components: {
		BRow,
		BCol,
		BaseButton,
		PaymentMethodHelp
	},
	mixins: [IdMixin, isMobile],
	props: {
		hasDebit: {
			type: Boolean,
			default: null
		},
		automationId: {
			type: String,
			default: null
		}
	},
	watch: {
		isEnglish() {
			this.updateUrlInfo();
		}
	}
})
export default class PremiumDetailsDebit extends Vue {
	padForm = null;
	defaultBrand = 'MBC';
	resource = null;
	securityPolicy = null;

	async created() {
		await this.updateUrlInfo();
		this.securityPolicy = await JSON.parse(sessionStorage.getItem('securityPolicy'));
	}

	async updateUrlInfo() {
		const resourceResponse = await memberCardInfo.getResourceUrlsByEmail(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		);
		this.resource = await axios.get(encodeURI(resourceResponse.data.json_padUrl));
	}

	/**
	 * computed property to get the local to determine what image to display.
	 */
	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	/**
	 * Obtain user brand if none is return default to MBC
	 */
	get brand() {
		return this.securityPolicy && this.securityPolicy.brand
			? this.securityPolicy.brand
			: this.defaultBrand;
	}

	get padLink() {
		if (this.resource?.data) {
			return this.resource.data.link;
		}
		return '';
	}

	get padTextInfo() {
		if (this.brand === 'OBC' || this.brand === 'QBC') {
			return this.$t('debit.info.textCan');
		}
		return this.$t('debit.info.text');
	}

	get padSubmitInfo() {
		if (this.brand === 'OBC' || this.brand === 'QBC') {
			return this.$t('debit.info.submitCan');
		}
		return this.$t('debit.info.submit');
	}

	get padMailInfo() {
		if (this.brand === 'OBC' || this.brand === 'QBC') {
			return this.$t('debit.info.mailCan');
		}
		return this.$t('debit.info.mail');
	}

	get padTitleInfo() {
		if (this.resource?.data) {
			return this.resource.data.title;
		}
		return null;
	}

	get padStreetInfo() {
		if (this.resource?.data) {
			return this.resource.data.addressLine1;
		}
		return null;
	}

	get padStreetInfo2() {
		if (this.resource?.data) {
			return this.resource.data.addressLine2;
		}
		return null;
	}

	get padCityInfo() {
		if (this.resource?.data) {
			return `${this.resource.data.city} , ${this.resource.data.province}`;
		}
		return null;
	}

	get padPostalInfo() {
		if (this.resource?.data) {
			return this.resource.data.postalCode;
		}
		return null;
	}

	get padPhoneNumberInfo() {
		if (this.resource?.data) {
			return this.resource.data.phoneNumber;
		}
		return null;
	}

	get padHoursInfo() {
		if (this.resource?.data) {
			return this.resource.data.hoursOfOperation;
		}
		return null;
	}

	downloadForm() {
		this.$store.dispatch('updateLoading', true);
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;
		// // Have to create this object here because IOS does not allow window.open() inside an async method.
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			var windowReference = window.open();
		}
		Forms.getForm(
			sessionStorage.getItem('email'),
			'PAD',
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		).then((response) => {
			this.$store.dispatch('updateLoading', false);
			if (response && (response.status === 201 || response.status === 200)) {
				if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
					windowReference.location = response.data;
				} else {
					window.open(response.data, '_blank');
				}
				this.$store.dispatch('clearError');
			}
		});
	}
}
</script>

<style lang="scss" scoped>
h4 {
	font-size: 19px;
}
h5 {
	font-family: $lato-font-family;
}
.email-link {
	font-weight: 400;
}
</style>

<i18n>
{
  "en": {
    "button": {
      "download": "Download Debit Change Form"
    },
    "debit": {
      "info": {
        "title": "You’re currently paying with pre-authorized debit.",
        "change": "Need to change your debit information?",
				"text": "To change your pre-authorized debit (PAD) information, you’ll need to download and complete the pre-authorized debit change form and send it back to us via email or regular mail. Once we get the completed form, it will take around 10 business days for changes to take effect.",
				"textCan": "To change your pre-authorized debit (PAD) information, you’ll need to download, print, and complete the pre-authorized debit change form then upload it via the secure link below, or send it by mail. Once we get the completed form, it will take around 10 business days for changes to take effect.",
        "reminder": "Just a reminder, updating or modifying your banking information will override any current payment details.",
				"submit": "Submit your form by email or mail to:",
				"submitCan": "To upload a completed form, follow the instructions at the link below.",
				"email": "Email:",
				"secureUpload": "Secure Upload:",
        "emailAddress": "ics@medaviebc.ca",
				"mail": "Mail:",
				"mailCan": "Submit your form by mail to:"
      },
      "noInfo": {
        "title": "Change your payment method to pre-authorized debit (PAD)"
      }
    }
  },
  "fr": {
    "button": {
      "download": "Formulaire pour le prélèvement automatique"
    },
    "debit": {
      "info": {
        "title": "Vous payez actuellement par prélèvement automatique. ",
        "change": "Vous devez modifier vos renseignements bancaires?",
				"text": "Pour modifier vos renseignements sur le prélèvement automatique, vous devez télécharger et remplir le formulaire pour le prélèvement automatique et l’envoyer par courriel ou par la poste. Lorsque nous recevrons le formulaire dûment rempli, nous effectuerons les modifications dans les 10 jours ouvrables suivants.",
				"textCan": "Pour modifier vos renseignements sur le prélèvement automatique, vous devrez télécharger, imprimer et remplir le formulaire pour le prélèvement automatique, puis le téléverser au moyen du lien sécurisé ci-dessous, ou encore nous l'envoyer par la poste. Lorsque nous recevrons le formulaire dûment rempli, nous effectuerons les modifications dans les 10 jours ouvrables suivants.",
        "reminder": "Nous vous rappelons que la mise à jour ou la modification de vos renseignements bancaires remplacera les renseignements financiers actuels.",
				"submit": "Soumettez votre formulaire par courriel ou par la poste à l’adresse suivante :",
				"submitCan": "Pour téléverser le formulaire dûment rempli, suivez la marche à suivre au lien ci-dessous.",
				"email": "Courriel : ",
				"secureUpload": "Téléversement sécurisé :",
        "emailAddress": "ics@medaviebc.ca",
				"mail": "Poste :",
				"mailCan": "Soumettez votre formulaire par la poste à l’adresse suivante :"
      },
      "noInfo": {
        "title": "Changer votre mode de paiement pour le prélèvement automatique"
      }
    }
  }
}
</i18n>
