<template>
	<BRow>
		<BCol class="card-images">
			<img src="@/assets/credit-card-images/Visa.svg" alt="Visa" />
			<img src="@/assets/credit-card-images/MasterCard.svg" alt="Mastercard" />
			<img src="@/assets/credit-card-images/Amex.svg" alt="Amex" />
			<img src="@/assets/credit-card-images/Discover.svg" alt="Discover" />
			<img src="@/assets/credit-card-images/JCB.svg" alt="JCB" />
		</BCol>
	</BRow>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import isMobile from '@/mixins/mobile';
import { BRow, BCol } from 'bootstrap-vue';

// @vue/component
@Component({
	name: 'CreditCardImages',
	components: {
		BRow,
		BCol
	},
	mixins: [IdMixin, isMobile]
})
export default class CreditCardImages extends Vue {}
</script>

<style lang="scss" scoped>
.card-images {
	min-width: 288px;
	& > img {
		margin-left: 5px;
		max-width: 34px;
		height: 24px;
	}
	& :first-child {
		margin-left: 0;
	}
}
</style>
