<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<div>
		<hr class="border-top" />
		<p class="title">{{ $t('title') }}</p>
		<p class="phoneNumberText mb-0">
			{{ $t('phoneNumberText') }}
			<PhoneNumberDisplay :phone-number="phoneNumberComp" /><br />
			{{ availableHours }}
		</p>
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
/**
 * This component renders a button that toggles contact information
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import PhoneNumberDisplay from '@/components/common/PhoneNumberDisplay';
import { formatPhoneNumber, formatPhoneNumberWithParentheses } from '@/utils/commonUtils.js';
// @vue/component
@Component({
	name: 'PaymentMethodHelp',
	components: {
		PhoneNumberDisplay
	},
	props: {
		phoneNumber: {
			type: String,
			default: null
		},
		hours: {
			type: String,
			default: null
		},
		requireDash: {
			type: Boolean,
			default: false
		},
		requireParentheses: {
			type: Boolean,
			default: false
		}
	}
})
export default class PaymentMethodHelp extends Vue {
	/**
	 * This computed prop checks if a phone number was passed in,
	 * if it wasn't then it used a default phone number.
	 */
	get phoneNumberComp() {
		if (this.phoneNumber) {
			if (this.requireDash) {
				return formatPhoneNumber(this.phoneNumber);
			} else if (this.requireParentheses) {
				return formatPhoneNumberWithParentheses(this.phoneNumber);
			}
			return this.phoneNumber;
		}
		return this.$t('phoneNumber');
	}

	get availableHours() {
		return this.hours ? this.hours : this.$t('hours');
	}
}
</script>

<!-- *************************************************************************
	Styles
	************************************************************************* -->
<style lang="scss" scoped>
.title {
	font-family: $lato-font-family;
	font-weight: $font-regular;
	font-size: $header-sub-font-size;
}
.border-top {
	margin-left: -25px;
	margin-right: -25px;
}
.text {
	font-family: $lato-font-family;
	font-weight: $font-light;
	font-size: $header-sub-font-size;
	clear: both;
}
.phoneNumberContainer {
	display: flex;
	flex-direction: row;
}
.phoneNumberText {
	font-family: $lato-font-family;
	font-weight: $font-light;
	font-size: $header-sub-font-size;
}
</style>

<i18n>
{
  "en": {
    "title": "Need help?",
    "phoneNumberText": "If you’re having problems changing your payment method please call:",
    "phoneNumber": "1-888-919-7378",
		"hours": "(Monday to Friday, regular business hours, Atlantic time.)"
  },
  "fr": {
    "title": "Besoin d’aide?",
    "phoneNumberText": "Si vous éprouvez des problèmes à modifier votre mode de paiement, téléphonez au numéro suivant :",
    "phoneNumber": "1-888-919-7378",
		"hours": "Du lundi au vendredi durant les heures d’ouverture habituelles (heure de l’Atlantique)"
  }
}
</i18n>
