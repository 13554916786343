<template>
	<div class="tabContent p-4">
		<BRow>
			<BCol>
				<div class="d-flex w-100 mb-3">
					<div class="flex-grow-1">
						<p class="h3 title">
							{{ hasCredit ? $t('creditCard.info.title') : $t('creditCard.noInfo.title') }}
						</p>
					</div>
					<div v-if="isEnglish" class="d-none d-xl-block">
						<img
							class="secured-moneris"
							src="@/assets/Secured-by-Moneris.svg"
							alt="Secured by Moneris"
						/>
					</div>
					<div v-else class="d-none d-xl-block">
						<img
							class="secured-moneris"
							src="@/assets/Securise-par-Moneris.svg"
							alt="Securise par Moneris"
						/>
					</div>
				</div>
			</BCol>
		</BRow>
		<template v-if="hasCredit">
			<BRow>
				<BCol>
					<p class="card-number-label">
						{{ $t('creditCard.info.card') }}
					</p>
				</BCol>
			</BRow>
			<BRow class="mb-3">
				<BCol>
					<span class="mr-3">{{ creditCard }}</span>
					<span>{{ expiryDate }}</span>
				</BCol>
			</BRow>
			<BRow class="d-block d-xl-none">
				<BCol>
					<div v-if="isEnglish">
						<img
							class="secured-moneris"
							src="@/assets/Secured-by-Moneris.svg"
							alt="Secured by Moneris"
						/>
					</div>
					<div v-else>
						<img
							class="secured-moneris"
							src="@/assets/Securise-par-Moneris.svg"
							alt="Securise par Moneris"
						/>
					</div>
				</BCol>
			</BRow>
			<BRow class="mt-4">
				<BCol>
					<p>{{ $t('creditCard.info.reminder') }}</p>
				</BCol>
			</BRow>
			<BRow>
				<BCol>
					<BaseButton
						:label="$t('button.update')"
						class="mb-3"
						:automation-id="getAutomationId('credit-card')"
						:data-tracking-id="getAutomationId('credit-card')"
						@click="goToMonerisIframe"
					/>
				</BCol>
			</BRow>
		</template>
		<template v-else>
			<BRow class="mt-n3">
				<BCol cols="12">
					<p>
						{{ $t('creditCard.noInfo.details') }}
					</p>
				</BCol>
				<BCol cols="12">
					<p>
						{{ $t('creditCard.noInfo.additionalDetails') }}
					</p>
				</BCol>
			</BRow>
			<CreditCardImages class="mb-4" />
			<BRow class="d-block d-xl-none mb-4">
				<BCol>
					<div v-if="isEnglish">
						<img
							class="secured-moneris"
							src="@/assets/Secured-by-Moneris.svg"
							alt="Secured by Moneris"
						/>
					</div>
					<div v-else>
						<img
							class="secured-moneris"
							src="@/assets/Securise-par-Moneris.svg"
							alt="Securise par Moneris"
						/>
					</div>
				</BCol>
			</BRow>
			<BRow>
				<BCol>
					<BaseButton
						class="mb-3"
						:label="$t('button.change')"
						:automation-id="getAutomationId('credit-card')"
						:data-tracking-id="getAutomationId('credit-card')"
						@click="goToMonerisIframe"
					/>
				</BCol>
			</BRow>
		</template>
		<BRow no-gutters>
			<BCol class="p-0">
				<PaymentMethodHelp
					:phone-number="needHelpPhoneNumber"
					require-dash
					:hours="needHelpHours"
				/>
			</BCol>
		</BRow>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import isMobile from '@/mixins/mobile';
import { BRow, BCol } from 'bootstrap-vue';
import PaymentMethodHelp from '@/components/common/PaymentMethodHelp';
import CreditCardImages from '@/components/common/CreditCardImages';
import BaseButton from '@/components/common/base/BaseButton';
import * as Routes from '@/constants/Routes.js';
//Models
import memberCardInfo from '@/models/MemberCardInfo';
//utils
import axios from 'axios';

// @vue/component
@Component({
	name: 'PremiumDetailsCreditCard',
	components: {
		BRow,
		BCol,
		BaseButton,
		PaymentMethodHelp,
		CreditCardImages
	},
	mixins: [IdMixin, isMobile],
	props: {
		creditCard: {
			type: String,
			default: null
		},
		expiryDate: {
			type: String,
			default: null
		},
		hasCredit: {
			type: Boolean,
			default: null
		},
		automationId: {
			type: String,
			default: null
		}
	},
	watch: {
		isEnglish() {
			this.updateUrlInfo();
		}
	}
})
export default class PremiumDetailsCreditCard extends Vue {
	tabs = [];
	resource = null;

	async created() {
		await this.updateUrlInfo();
	}

	async updateUrlInfo() {
		const resourceResponse = await memberCardInfo.getResourceUrlsByEmail(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		);
		this.resource = await axios.get(encodeURI(resourceResponse?.data.json_padUrl));
	}

	/**
	 * computed property to get the local to determine what image to display.
	 */
	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	/**
	 * Obtain user brand if none is return default to MBC
	 */
	get brand() {
		let securityPolicy = JSON.parse(sessionStorage.getItem('securityPolicy'));
		return securityPolicy && securityPolicy.brand ? securityPolicy.brand : this.defaultBrand;
	}

	get needHelpPhoneNumber() {
		if (this.resource?.data) {
			return this.resource.data.phoneNumber;
		}
		return null;
	}

	get needHelpHours() {
		if (this.resource?.data) {
			return this.resource.data.hoursOfOperation;
		}
		return null;
	}

	/*
	 * Will set the values in the tabs array.
	 */
	mounted() {
		this.tabs.push(this.$t('tab.creditCard'));
		this.tabs.push(this.$t('tab.preAuthDebit'));
	}
	goToMonerisIframe() {
		this.$router.push({ name: Routes.MONERIS });
	}
}
</script>

<style lang="scss" scoped>
.secured-moneris {
	width: 175px;
}
.card-number-label {
	font-size: 18px;
	font-family: $josefin-font-family;
}
</style>
<i18n>
{
  "en": {
    "url": {},
    "tab": {
      "creditCard": "Credit Card",
      "preAuthDebit": "Pre-Authorized Debit"
    },
    "button": {
      "update": "Update Credit Card",
      "change": "Change to credit card"
    },
    "creditCard": {
      "info": {
        "title": "Your current method of payment is by credit card.",
        "card": "Credit Card",
        "reminder": "Just a reminder, updating or modifying your credit card information will override any current payment details."
      },
      "noInfo": {
        "title": "Pay with a credit card",
				"details": "You will be redirected to a secure page to enter your credit card details.",
				"additionalDetails": " If you wish to change your payment method (from pre-authorized withdrawal to credit card), you must do so within 72 working hours before the monthly debit. Otherwise, the change will take effect the following month."
      }
    }
  },
  "fr": {
    "url": {},
    "tab": {
      "creditCard": "Carte de crédit",
      "preAuthDebit": "Prélèvement automatique"
    },
    "button": {
      "update": "Modifier la carte de crédit",
      "change": "Utiliser une carte de crédit."
    },
    "creditCard": {
      "info": {
        "title": "Votre mode de paiement actuel est par carte de crédit.",
        "card": "Carte de crédit",
        "reminder": "Nous vous rappelons que la mise à jour ou la modification des renseignements sur votre carte de crédit remplacera les renseignements financiers actuels."
      },
      "noInfo": {
        "title": "Payer avec une carte de crédit",
				"details": "Vous serez redirigé vers une page sécurisée pour entrer les renseignements de votre carte de crédit.",
				"additionalDetails": "Pour changer de mode de paiement (passage de prélèvement automatique à carte de crédit), le changement doit être fait dans les 72h ouvrables avant le prélèvement mensuel, sinon, le changement sera effectif le mois suivant."
      }
    }
  }
}
</i18n>
